<template>
  <div class="timeline-wrap" ref="timeline" :style="cssVars">
    <div class="arrow-tim-wrap">
      <div v-if="showArrowTime" class="time-and-live">
        <div class="time-only">
          <DateRangeSelect
            class="dt-select"
            v-model="dateTime"
            :range="false"
            :format="`YYYY-MM-DD HH:mm:ss`"
            @confirm="onDayClick"
            @showDatePanel="getshowDatePanel"
          />
          <!-- <div class="vc-cal" id="vcCal">
            <vc-date-picker 
              :locale="lang"
              v-show="vCalendar"
              mode="dateTime"
              v-model="dateTime"
              is24hr
              :minute-increment="5"
              :timezone="timezone"
              :max-date='new Date()'
              >
              <template v-slot:footer>
                <div class="cal-ok">
                  <button class="cal-okbtn" @click="onDayClick">{{ $t('confirm') }}</button>
                </div>
              </template>
            </vc-date-picker>
          </div>
          <div class="p-time">
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('the_day_before')/*前一天*/" placement="top">
              <img src="@/assets/icons/arrowLeft.svg" 
                @click="setTimByArrow('minus', 'day')"
                :disabled="clicked"
                style="cursor: pointer; margin-right: 3px;"/>
            </el-tooltip>
            <span @click="onDateClick" style="cursor: pointer;">{{ pointerDate }}</span>
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('the_day_after')/*後一天*/" placement="top">
              <img src="@/assets/icons/arrowRight.svg" 
                @click="setTimByArrow('add', 'day')"
                :disabled="clicked"
                style="cursor: pointer; margin-left: 3px;"/>
            </el-tooltip> 
            &nbsp;&nbsp;&nbsp;&nbsp;
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('a_minute_ago')/*前一分鐘*/" placement="top">
              <img src="@/assets/icons/arrowLeft.svg" 
                @click="setTimByArrow('minus', 'min')"
                :disabled="clicked"
                style="cursor: pointer; margin-right: 3px;"/>
            </el-tooltip> 
            <span @click="onDateClick" style="cursor: pointer;">{{ pointerTime }}</span>
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('one_minute_after')/*後一分鐘*/" placement="top">
              <img src="@/assets/icons/arrowRight.svg" 
                @click="setTimByArrow('add', 'min')"
                :disabled="clicked"
                style="cursor: pointer; margin-left: 3px;"/>
            </el-tooltip> 
          </div> -->
        </div>
        <!-- 有 live 權限 + 非 live => 才有 跳至直播畫面 按鈕 -->
        <template>
          <button v-if="/*permissionV2video === 0 &&*/ liveMode" disabled="false" class="live-btn">
            <li :class="{ live: liveMode }">{{ /*小點*/ }}</li>
            <span>LIVE</span>
          </button>
          <el-tooltip
            v-else
            popper-class="el-tooltip"
            effect="dark"
            v-delTabIndex
            :visible-arrow="false"
            :content="$t('jump_to_live') /*跳至直播畫面*/"
            placement="top"
            class="live-btn"
          >
            <div>
              <button class="vod" :class="{'live-vod': videoLiveMode === true}" @click="onSwitchLiveMode">
                <li>{{ /*小點*/ }}</li>
                <span>LIVE</span>
              </button>
            </div>
          </el-tooltip>
        </template>
      </div>
      <div v-else class="date-time">
        <span>{{ pointerDate }}</span>
        <span>{{ pointerTime }}</span>
      </div>
      <div class="hour-div">
        <div
          v-for="(item, index) in axisRangeList"
          :key="index"
          @click="hourClick(item.value)"
          class="hour-btn"
          :class="{ selected: item.value === axisRangeValue }"
        >{{item.label}}</div>
      </div>
    </div>
    <svg class="timeaxis-svg">
      <g class="data-g" />
      <g class="timeaxis-g" />
    </svg>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import * as d3 from 'd3'
import moment from 'moment'
//import ArrowTim from './TimeAxis/ArrowTime.vue'
import DateRangeSelect from '../Base/DateRangeSelect.vue'

// 一律以中心點縮放 研究中
// 日期套件尚未加上去
export default {
  name: 'timeline',
  props: {
    liveList: Array,
    videoList: Array,
    videoIndex: Number,
    liveMode: Boolean,
    videoCurrentSeconds: Number,
    showArrowTime: Boolean,
    reTimeAxis: Boolean,
    permissionV2video: Number,
    axisRangeList: {
      type: Array,
      default: () => [
        { label: '24H', value: 12 },
        { label: '8H', value: 4 },
        { label: '1H', value: 0.5 },
        { label: '10M', value: 0.08333333 },
      ]
    },
    axisRange: {
      type: Number,
      default: 4
    }
  },
  components: {
    DateRangeSelect
  },
  data() {
    return {
      svgWidth: null,
      timeRange: 2, // hrs
      SVG: null,
      xDomain: null,
      zDomain: [
        new Date().getTime(),
        new Date().getTime() + 2 * 60 * 60 * 1000
      ],
      leftTime: new Date().getTime(),
      axisTopMargin: 60,
      pointerTim: null,
      dayLineList: null,
      initialWidth: null,
      liveStartTime: new Date(),
      videoCurrentTime: null,
      manualMoveTim: false, // 按箭頭 或 拖動時間軸 改動時間 => 要停止目前影片 並找對應時間的影片播放
      //updateRectListTimeout: null // 直播進行中 videoList每一分鐘更新一次 讓畫面的歷史影片長方形可以保持在最新狀態
      dateTime: new Date(),
      vCalendar: false,
      cal_left: 0,
      cal_top: 0,
      timeFormat: '%H:%M',
      timeMinute: 30, // 時間間隔 30分鐘
      drawData: [], // 濃縮起迄時間 縮短化區塊時間
      dragTimeout: null,
      minPointerTim: null, // 紀錄影片區塊最小時間
      maxPointerTim: null, // 紀錄影片區塊最大時間
      TimByArrow: false,
      clicked: false,
      pointerDate: '',
      pointerTime: '',
      showDatePanel: false,
    }
  },
  mounted() {
    this.addMethodInDate()
    this.dateTime = new Date()
    let min = moment(this.dateTime).tz(this.timezone).format('mm')
    this.dateTime.setMinutes(min)
    
    this.setTimeAxisFormat()
    this.checkResize()
    this.SVG = d3.select('.timeaxis-svg')
    this.initialWidth = this.$refs.timeline.clientWidth
    this.changeSvgWidth()
    this.zoomX = this.x.domain() // 只有在mounted時一樣 zoom或改變寬度後 zoomX和x就不一樣了
    window.addEventListener('resize', this.changeSvgWidth)
    // 拖動時間軸和改變時間軸寬度 會重新渲染時間軸
    // 時間軸每次重新渲染 都會重新取得 左邊跟右邊的時間 和 時間軸寬度
    this.setZoom()
    this.SVG.call(this.zoom).on('wheel.zoom', null).on('dblclick.zoom', null)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeSvgWidth)
  },
  computed: {
    ...mapState(['LeftMenuOpen', 'RightMenuOpen', 'TopMenuHeightLevel']),
    ...mapGetters(['timezone']),
    lang() {
      const navLang = navigator.language
      let language = 'en'
      if (navLang === 'zh-TW') language = 'zh-tw'
      if (navLang === 'en-US' || navLang === 'en') language = 'en'
      return language
    },
    cssVars() {
      return {
        "--cal-left": this.cal_left + 'px',
        "--cal-top": this.cal_top + 'px',
      }
    },
    x: {
      get() {
        if (this.xDomain) {
          return d3
            .scaleTime()
            .domain(this.xDomain)
            .range([0, this.initialWidth])
        }
        let startTim = new Date().getTime() // 3.5
        return (
          d3
            .scaleTime()
            // 要以當下時間為正中央
            .domain([
              new Date(startTim).addHours(Math.abs(this.axisRangeValue) * -1),
              new Date(startTim).addHours(Math.abs(this.axisRangeValue))
            ])
            .range([0, this.initialWidth])
        )
      },
      set(val) {
        this.xDomain = val
      }
    },
    zoomX: {
      // 更動寬度時 要用目前的寬度 還有zoom後的時間區間 重新產生xScale 再渲染時間軸
      // 要放在computed裡面的變數 預設要有值 不然就會偵測到空或null的預設值然後報錯
      get() {
        return (
          d3
            .scaleTime()
            // .domain([this.leftTime, this.leftTime + this.timeRange * 60 * 60 * 1000])
            .domain(this.zDomain)
            .range([0, this.svgWidth])
        )
      },
      set(val) {
        this.zDomain = val
      }
    },
    axisRangeValue: {
      get() {
        return this.axisRange
      },
      set(val) {
        this.$emit('update:axisRange', val)
      }
    },
    videoLiveMode() {
      if (this.videoList?.length === 0 || this.videoIndex < 0) return false
      // 現在時間點 > video.startTime + video.currentTime => live 的 vod
      const userId = this.videoList[this.videoIndex].user.id
      const liveDev = this.liveList.find((live) => live.id === userId)

      if (!this.liveMode && liveDev) return true
      return false
    }
  },
  watch: {
    liveMode() {
      // 直播用當下時間 歷史影片用當下影片時間 去比對差異 並移動時間軸
      this.setAxisTime()
      // if (this.liveMode) {
      //   // 直播進行中 videoList每一分鐘更新一次 讓畫面的歷史影片長方形可以保持在最新狀態
      //   this.updateVideoListWhenLive()
      // } else {
      //   if (this.updateRectListTimeout) clearTimeout(this.updateRectListTimeout)
      //   this.updateRectListTimeout = null
      // }
      if (this.liveMode) {
        this.liveStartTime = new Date()
      }
    },
    videoIndex() {
      // 歷史影片模式下 videoIndex變動就重畫時間軸
      if (!this.liveMode && this.videoIndex >= 0) {
        this.setAxisTime()
      }
    },
    videoCurrentSeconds(sec) {
      // ＊＊＊＊＊＊＊＊＊ 這裡會跳回當下時間 ＊＊＊＊＊＊＊＊＊＊
      if (this.videoCurrentSeconds == 0) {
        this.liveStartTime = new Date()
      }

      // 開啟日曆時 不更新時間
      if (!this.showDatePanel) {
        if (this.liveMode) {
          this.dateTime = new Date()
        } else if (!this.liveMode /*&& this.videoLiveMode*/) {
          // 現在時間點 > video.startTime + video.currentTime => live 的 vod
          const v = this.videoList[this.videoIndex]
          if (v) {
            const currentTimeMs = new Date(v.startTime).getTime() + sec*1000
            this.dateTime = new Date(currentTimeMs)
          }
        }
      }

      if (this.manualMoveTim) return
      this.setAxisTime()
    },
    pointerTim() {
      // 有變動就emit時間出去 重load影片進度
      if (this.pointerTim > new Date()) {
        // 超出現在時間 就直接加一天 加一天這個func有判斷：
        // 加了一天後超過當下時間就維持在當下時間 所以直接拿來複用
        this.setDate('add', 'day')
      }
      this.showPointerTim()
      this.$emit('loadVideoWithPointerTim', this.pointerTim) // 畫GPS軌跡
      this.onLivedraw() // 濃縮畫法 縮短拖拉時間
      // console.log(this.pointerTim)
      // this.$set(this.attributes[0], 'dates', moment(this.pointerTim).format('YYYY-MM-DD'))
    },
    LeftMenuOpen() {
      this.changeSvgWidth()
      this.checkResize()
    },
    RightMenuOpen() {
      this.changeSvgWidth()
      this.checkResize()
    },
    TopMenuHeightLevel() {
      this.checkResize()
    },
    zoomX() {
      // zoomX在拖動、滾時間軸 還有按箭頭更改時間時會重新assign
      // 每重新assign 就要重抓中心時間 重畫day interval line&text
      // 然後重新渲染時間軸
      this.setPointerTim()
      if (this.TimByArrow) {
        this.$emit('stopAndPlayAtSpecificTim', this.pointerTim)
        this.TimByArrow = false
      }
      if (!this.reTimeAxis || this.drawData.length == 0) {
        this.drawVideoList() // 重新抓取有影片區塊
      }
      this.setPointerPosition() // 畫時間軸中心時間紅三角
      // update axes with these new boundaries
      this.drawAxis() // 畫時間刻度
    },
    axisRangeValue() {
      this.rescaleTimeAxis()
      this.checkResize()
    }
  },
  methods: {
    addMethodInDate() {
      // 在Date新增方法 方便增加天數 畫path和text要用
      Date.prototype.addDays = function (days) {
        this.setDate(this.getDate() + days)
        return this
      }

      Date.prototype.addSecs = function (secs) {
        this.setTime(this.getTime() + secs * 1000)
        return this
      }

      Date.prototype.addMins = function (mins) {
        this.setTime(this.getTime() + mins * 60 * 1000)
        return this
      }

      Date.prototype.addHours = function (hours) {
        this.setTime(this.getTime() + hours * 60 * 60 * 1000)
        return this
      }
    },
    removeTimeAxis() {
      this.drawData = []
      d3.selectAll('.videoLine').remove()
    },
    rescaleTimeAxis() {
      this.setTimeAxisFormat()
    
      this.zDomain= [
        new Date(this.pointerTim).getTime() - (this.axisRangeValue * 60 * 60 * 1000),
        new Date(this.pointerTim).getTime() + (this.axisRangeValue * 60 * 60 * 1000)
      ]
      this.xDomain = this.zDomain
      this.x = this.zDomain
      this.zoomX = this.zDomain
      // 切換解析度 要重設 zoom
      this.SVG.call(this.zoom.transform, d3.zoomIdentity).on('wheel.zoom', null).on('dblclick.zoom', null)
    },
    setTimeAxisFormat() {
      switch (this.axisRangeValue) {
        case 0.04166667:
          this.timeFormat = '%H:%M'
          this.timeMinute = 1
          break
        case 0.08333333:
          this.timeFormat = '%H:%M'
          this.timeMinute = 1
          break
        case 0.5:
          this.timeFormat = '%H:%M'
          this.timeMinute = 5
          break
        case 1:
          this.timeFormat = '%H:%M'
          this.timeMinute = 30
          break
        case 12:
          this.timeFormat = '%H'
          this.timeMinute = 60
          break
        default:
          this.timeFormat = '%H:%M'
          this.timeMinute = 30
          break
      }
    },
    hourClick(val) {
      if (this.axisRangeValue == val) return
      this.$emit('update:axisRange', val)
    },
    onDateClick() {
      if (this.permissionV2video > 0) {
        this.vCalendar = !this.vCalendar
      }
    },
    showPointerTim() {
      let week = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
      let date = moment(this.pointerTim).tz(this.timezone).format('YYYY-MM-DD')
      let time = moment(this.pointerTim).tz(this.timezone).format('HH : mm : ss')
      this.pointerDate = date + ' (' + this.$t(`${week[new Date(date).getDay()]}`) + ')'
      this.pointerTime = `${time}`
    },
    // updateVideoListWhenLive() {
    //   this.updateRectListTimeout = setTimeout(() => {
    //     this.$emit('getHistoryVideoList')
    //     this.updateVideoListWhenLive()
    //   }, 1000 * 60)
    // },
    setTimByArrow(typ, interval) {
      if (this.clicked) return
      if (this.permissionV2video > 0) {
        this.clicked = true
        this.setDate(typ, interval)
        this.stopAndPlayAtSpecificTim()
        setTimeout(() => {
          this.clicked = false
        }, 500)
      }
    },
    stopAndPlayAtSpecificTim() {
      //this.manualMoveTim = true
      this.TimByArrow = true
      //this.$emit('stopAndPlayAtSpecificTim', this.pointerTim)
    },
    getTimeDelta() {
      // 計算時間差 帶入setAxisTime改變時間軸domain
      // 直播模式 用現在時間 歷史模式 用即將播放的歷史影片計算 和pointerTim的時間差
      let tim
      if (this.liveMode) {
        tim = this.liveStartTime
      } else {
        if (this.videoIndex != -1) {
          tim = new Date(
            // 把時間轉換成UI畫面呈現的時間
            this.utcToTaipeiTime(this.videoList[this.videoIndex].startTime)
          )
        } else tim = new Date(this.pointerTim)
      }
      if (this.videoCurrentSeconds) {
        tim = new Date(tim).addSecs(this.videoCurrentSeconds)
      }
      let minDiff = moment(tim).diff(this.pointerTim) / 1000 / 60
      return minDiff
    },
    setAxisTime(timDelta = null) {
      // 移動後 更改this.x domain 下次zoom才會知道this.x位移過了
      // 改變zoomX 就會重新渲染一次時間軸的UI
      if (!timDelta) timDelta = this.getTimeDelta()

      let [orgStart, orgEnd] = this.x.domain() // 為了移動this.x的原點
      let [zoomS, zoomE] = this.zoomX.domain() // 為了讓畫面套用最新的效果
      this.x = [
        new Date(orgStart).addMins(timDelta),
        new Date(orgEnd).addMins(timDelta)
      ]
      this.zoomX = [
        new Date(zoomS).addMins(timDelta),
        new Date(zoomE).addMins(timDelta)
      ]
    },
    setDate(typ, interval) {
      let delta = typ == 'add' ? 1 : -1
      let min = interval == 'day' ? 24 * 60 : 1
      let minDiff = delta * min
      // 最晚只能到當下時間
      if (new Date(this.pointerTim).addMins(minDiff) > new Date()) {
        minDiff = moment(new Date()).diff(this.pointerTim) / 1000 / 60
      }
      this.setAxisTime(minDiff)
    },
    utcToTaipeiTime(tim) {
      // 把時間轉換成UI畫面呈現的時間
      // 時間用moment.utc()轉換就是utc時間;若用moment()轉換就會是當地時區的時間
      // 根據時區轉換
      return moment(new Date(tim)).tz(this.timezone).format(
        'YYYY/MM/DD HH:mm:ss'
      )
    },
    drawWhiteBg() {
      d3.select('.bg').remove()
      // 畫白色透明底條
      let h = 10
      d3.select('.data-g')
        .append('rect')
        .attr('class', 'bg')
        .attr('width', this.svgWidth)
        .attr('height', h)
        .attr('fill', '#ffffff6b')
        .attr('stroke', 'none')
        .attr('x', 0)
        .attr('y', this.axisTopMargin - h)
    },
    onLivedraw() {
      const processedData = []
      this.drawData.forEach(item => {
        // 停止時間大於現在時間的話 就不畫
        const start = this.zoomX(new Date(item.startTime))
        const e = this.zoomX(new Date(item.stopTime))
        const end = (e > this.zoomX(new Date())) ? this.zoomX(new Date()) : e
        let width = end - start
        if (!width || width < 0) width = 0
        processedData.push({
          start,
          end,
          width
        })
      })
      if (this.liveMode) { // 直播模式 要從直播開始時間補畫到當下時間
        const s = this.zoomX(this.liveStartTime)
        // 避免當下時間超過中軸 畫到直播時間
        const e = this.zoomX(new Date(this.pointerTim))
        let width = e - s
        if (!width || width < 0) width = 0
        processedData.push({
          start: s,
          end: e,
          width
        })
      }

      d3.select('.data-g')
        .selectAll('.videoLine')
        .data(processedData)
        .join('rect')
        .attr('class', 'videoLine')
        .attr('width', (d) => d.width)
        .attr('height', 10)
        .attr('fill', '#fff')
        .attr('stroke', 'none')
        .attr('x', (d) => d.start)
        .attr('y', this.axisTopMargin - 10)
    },
    drawDataRect() {
      if (!this.videoList.length) {
        this.removeTimeAxis()
        return
      } 
      let drawList = this.videoList.filter((item) => item)
      let olds = '' //因為是倒排序 所以判別 startTime
      let olde = ''
      this.drawData = []
      drawList.forEach((item) => {
        if (item.startTime < this.minPointerTim) this.minPointerTim = item.startTime
        if (item.stopTime > this.maxPointerTim) this.maxPointerTim = item.stopTime
        if (!olds && !olde) {
          olds = item.startTime
          olde = item.stopTime
          this.minPointerTim = item.startTime
          this.maxPointerTim = item.stopTime
        } else {
          if (item.stopTime == olds) olds = item.startTime
          else {
            this.drawData.push({startTime: olds, stopTime: olde})
            olds = item.startTime
            olde = item.stopTime
          }
        }
      })
      this.drawData.push({startTime: olds, stopTime: olde})
      //控制不要一直重劃 時間軸
      this.$emit('setReTimeAxis', true)
      this.onLivedraw() // 濃縮畫法 縮短拖拉時間
    },
    drawVideoList() {
      this.drawWhiteBg() // 畫白色透明底條
      this.drawDataRect() // 畫白色實心底條 顯示歷史影片所在位置
    },
    setPointerTim() {
      this.pointerTim = this.zoomX.invert(this.svgWidth / 2)
    },
    // 畫時間軸中心時間紅三角
    setPointerPosition() {
      let w = this.svgWidth / 2
      let h = this.axisTopMargin
      d3.select('.pointer').remove()
      d3.select('.timeaxis-svg')
        .append('polygon')
        .attr('points', `${w - 8},${h - 21} ${w},${h - 10} ${w + 8},${h - 21}`)
        .attr('stroke', '#FFC600') // color_DB2B39
        .attr('fill', '#FFC600') // color_DB2B39
        //   .attr('stroke', 'red')
        //   .attr('fill', 'red')
        .attr('class', 'pointer')
    },
    changeSvgWidth() {
      // window resize或是開關左右bar都要重新調整時間軸寬度
      this.svgWidth = this.$refs.timeline.clientWidth
      this.checkResize()
    },
    xAxis(x) {
      return d3
        .axisBottom(x)
        .tickFormat((tim) => {
          return d3.timeFormat(this.timeFormat)(tim)
        })
        .ticks(d3.timeMinute.every(this.timeMinute))
    },
    drawAxis() {
      this.gxAxis = d3
        .select('.timeaxis-g')
        .attr('font-size', '14px')
        .attr('line-height', '19px')
        .attr('transform', 'translate(0,' + this.axisTopMargin + ')')
        .call(this.xAxis(this.zoomX))
    },
    setZoom() {
      this.zoom = d3.zoom().
        extent([[0,0],[300,300]]).
        on("start", this.dragstarted).
        on('zoom', this.dragged).
        on("end", this.dragended)
    },
    // 開始拖拉時間軸
    dragstarted() {
      //console.log(this.permissionV2video) 無權限不可拖拉 
      if (this.permissionV2video > 0) {
        if(this.dragTimeout) { clearTimeout(this.dragTimeout) }
        this.manualMoveTim = true
        //console.log(e.transform.x)
      }
    },
    // 時間軸拖拉中
    dragged(e) {
      if (this.permissionV2video > 0) {
        let newX = e.transform.rescaleX(this.x)
        this.zoomX = newX.domain()
        this.onLivedraw() // 濃縮畫法 縮短拖拉時間
      }
    },
    // 時間軸拖拉結束
    dragended() {
      if (this.permissionV2video > 0) {
        if(this.dragTimeout) { clearTimeout(this.dragTimeout) }
        this.dragTimeout = setTimeout(() => {
          this.$emit('stopAndPlayAtSpecificTim', this.pointerTim)
          this.$emit('loadVideoWithPointerTim', this.pointerTim)
        }, 400)
      }
    },
    doCalender(val) {
      this.vCalendar = val
    },
    onDayClick(dateTime) {
      // console.log(`[onDayClick] dateTime:`, dateTime)
      this.dateTime = dateTime
      if (this.dateTime) {
        // console.log(this.dateTime)
        let newDateTime = moment(this.dateTime).tz(this.timezone).format('YYYY-MM-DD HH:mm:ss')
        // 移動時間軸到指定時間
        const minDiff = moment(new Date(newDateTime)).diff(this.pointerTim) / 1000 / 60
        this.setAxisTime(minDiff)
        this.$emit('stopAndPlayAtSpecificTim', new Date(newDateTime))
      }
      this.vCalendar = false
    },
    checkResize() {
      let element = document.getElementsByClassName('timeline-wrap')[0]
      let rect = element.getBoundingClientRect()
      this.cal_left = Math.round(rect.left)+45
      this.cal_top = Math.round(rect.top)-335
      // console.log(rect.top, rect.right, rect.bottom, rect.left, element.offsetTop, element.offsetHeight)
      this.drawVideoList() // 重新抓取有影片區塊
    },

    // ---
    onSwitchLiveMode() {
      this.$emit('switchLiveMode')
    },
    getshowDatePanel(show) {
      this.showDatePanel = show
    }
  },
  created() {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)){
        this.vCalendar = false
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.timeline-wrap {
  width: 100%;
  background-color: $color_39425D;
  display: flex;
  flex-direction: column;
  height: px2rem(90);
  box-sizing: border-box;
  position: relative;
}

.arrow-tim-wrap {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: $color_FFF;
  top: px2rem(10);
  left: 0;
  width: 100%;
}
.time-and-live {
  display: flex;
  // background-color: #f00;
}

.hide {
  display: none;
}

.timeaxis-svg {
  box-sizing: border-box;
  width: 100%;
  height: px2rem(90);
  cursor: grab;
}

.date-time {
  padding-left: 16px;
  font-size: 20px;
  line-height: 24px;
}

.date-time span {
  margin-right: 12px;
}

.hour-div {
  display: flex;
  position: absolute;
  top: -2px;
  right: 10px;
}

.hour-btn {
  width: 48px;
  height: 32px;
  outline: none;
  border: none;
  color: #FFFFFF80;
  background: #6E7D934d;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hour-btn:first-child {
  border-radius: 6px 0 0 6px;
}

.hour-btn:last-child {
  border-radius: 0 6px 6px 0;
}

.hour-btn:hover {
  background: $color_6E7D93_50;
}

.hour-btn.selected {
  color: $color_FFF;
  background: $color_6E7D93;
}

.dt-select:deep {
  .overlay {
    color: $color_FFF;
    background-color: $color_6E7D93_30;
    &:hover {
      cursor: pointer;
      background-color: $color_6E7D93_50;
    }
  }
}

.live-btn {
  // position: relative;
  // top: px2rem(-2);
  // left: px2rem(300 + 4);
  display: flex;
  margin-left: 0.5rem;
  border-radius: 0.375rem; // 6px
  // height: 2rem;
  padding: 0rem 0.5rem;
  font-size: 1rem;
  // background-color: $color_6E7D93_30;

  li {
    width: 1rem;
    color: $color_9D9D9D;
    &.live {
      color: $color_4C68EB;
    }
  }

  .vod {
    border-radius: 0.375rem; // 6px
    padding: 0rem 0.5rem;
    background-color: $color_6E7D93_30;
    transition: background-color 0.3s ease-in-out;
  }
  .live-vod {
    border-radius: 0.375rem; // 6px
    padding: 0rem 0.5rem;
    background-color: $color_6E7D93_30;
    &:hover {
      cursor: pointer;
      background-color: $color_6E7D93_50;
    }
  }

}
</style>

<style lang="scss">
path.domain {
  stroke: #fff;
}
.tick text {
  fill: #fff;
}
.tick line {
  stroke: #fff;
}

.pointer-tim {
  display: flex;
  align-items: center;
  justify-content: center;
  color: cornflowerblue;
  height: 30px;
}
.vc-cal {
  position: fixed;
  left: var(--cal-left);
  top: var(--cal-top);
  z-index: 5;
}
.cal-ok {
  position: fixed;
  margin-top: -38px;
  margin-left: 158px;
}
.cal-okbtn {
  width: 80px;
  height: 30px;
  border: .1px solid #4A5C78;
  border-radius: 5px;
  text-align: center;
  /* font: normal normal normal 18px/28px Roboto; */
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #4A5C78;
  opacity: 1;
}
.cal-okbtn:hover {
  color: #FFF;
  background: #3182CE 0% 0% no-repeat padding-box;
}
.p-time {
  display: flex;
  position: absolute;
  margin-left: 10px;
}

.p-time span {
  font-size: 20px;
  line-height: 20px;
  user-select: none;
}
</style>
